import * as React from 'react';

import { Box, BoxProps } from '@leagueplatform/genesis-commons';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import {
  APP_NAV_DEFAULT_HEIGHT,
  AuthedWebviewModal,
  ERROR_TYPES,
  ErrorState,
  FEATURE_FLAGS,
  LinkHandler,
  LiveChat,
  LoadingSpinner,
  QueryParamModal,
  useSsoDeepLink,
} from '@highmark-web/highmark-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { AppNavBarComponent } from './nav.component';
import { AllMyHealthFooter } from './footer/allmyhealth-footer.component';

const MAIN_CONTENT_MIN_HEIGHT = `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`;

export const PrivateBasePage: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();
  useSsoDeepLink(LinkHandler);

  const { isEnabled: isLiveChatNavigateAwayEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.LIVE_CHAT_NAVIGATE_AWAY,
  );

  return (
    <>
      <AppNavBarComponent />
      <AuthedWebviewModal linkHandler={LinkHandler} />
      <QueryParamModal />
      <Box
        as="main"
        id="main-content"
        position="relative"
        flex="1"
        overflowX="hidden"
        // This is so any page will take up the whole screen regardless of content
        minHeight={MAIN_CONTENT_MIN_HEIGHT}
        {...boxProps} // eslint-disable-line
      >
        <ObservabilityErrorBoundary
          fallback={
            <ErrorState
              errorType={ERROR_TYPES.API_ERROR}
              styleProps={{
                minHeight: MAIN_CONTENT_MIN_HEIGHT,
              }}
            />
          }
          errorContext={{
            context: {
              Context: { location: 'BasePage ErrorBoundary' },
            },
          }}
        >
          <React.Suspense fallback={<LoadingSpinner />}>
            {children}
          </React.Suspense>
        </ObservabilityErrorBoundary>
      </Box>
      <AllMyHealthFooter />
      {isLiveChatNavigateAwayEnabled ? <LiveChat /> : null}
    </>
  );
};
