import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  APP_MANAGED_ROUTES,
  highmarkCommon,
  LinkHandler,
  AUTHED_WEBVIEW_URL_NAMES,
  getAuthedWebviewUrl,
  useCurrentRegionUbkHeader,
} from '@highmark-web/highmark-common';
import { formsLibrary } from '@leagueplatform/engen-capability-forms-web';
import { cobLibrary } from '@leagueplatform/engen-capability-cob-library';
import { accountManagementLibrary } from '@leagueplatform/engen-capability-account-management';

// SDK initializers that do not use the Config API
export const initializeNonConfigSDKs = () => {
  League.overwriteRoutePaths({
    [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
    [LEAGUE_MODULE_NAMES.careDiscovery]: '/care-discovery',
    [LEAGUE_MODULE_NAMES.liveChat]: APP_MANAGED_ROUTES.LIVE_CHAT,
    [LEAGUE_MODULE_NAMES.messaging]: APP_MANAGED_ROUTES.MESSAGING,
  });

  highmarkCommon.config({
    highmarkMemberPortalUrl: import.meta.env.VITE_HIGHMARK_MEMBER_PORTAL_URL,
    highmarkRestApiUrl: `${import.meta.env.VITE_HIGHMARK_REST_API}/digital`,
    highmarkSsoAppUrl: import.meta.env.VITE_HIGHMARK_SSO_APP_URL,
    qualtricsSurveyUrl: import.meta.env.VITE_QUALTRICS_SURVEY_URL,
  });

  formsLibrary.config({
    HIGHMARK_API_URL: import.meta.env.VITE_HIGHMARK_REST_API,
    AMH_CDN_URL: import.meta.env.VITE_HIGHMARK_CONTENT_URL,
    useCurrentRegionUbkHeader,
  });

  cobLibrary.config({
    HIGHMARK_API_URL: import.meta.env.VITE_HIGHMARK_REST_API,
    useCurrentRegionUbkHeader,
  });

  accountManagementLibrary.config({
    LinkHandler,
    AUTHED_WEBVIEW_URL_NAMES,
    getAuthedWebviewUrl,
    HIGHMARK_API_URL: `${import.meta.env.VITE_HIGHMARK_REST_API}/digital/v1`,
  });
};
